<script>
export default {

}
</script>

<style>
html,body, .router-view, #shareView{
    background-color: transparent !important;
    background: transparent !important;
}</style>