<template>
    <div>
        <div v-if="!embed" style="display: flex;justify-content: space-between;height: 45px;margin: 32px 5%;align-items: baseline;">
            <a href="https://www.yopbox.com"><img src="@/assets/yopbox.png" alt="" style="height: 45px;"></a>
            <div>
                <a id="bouton-menu" href="https://frame.yopbox.com/" target="_blank" class="bouton_beta w-inline-block">
                    <div class="button_label-menu">{{ $t('ShareView.tryIt') }}</div>
                    <div class="background-fill"></div>
                </a>
            </div>

        </div>

        <loadEmbedStyle v-if="embed"/>

        <div v-if="video != null" id="shareView"  :class="[video.vdo_format, {'embed': embed} ]">
            <div v-if="video != null"  id="playerContainer" :class="[video.vdo_format]">

                <Player :src="video.vdo_files" :poster="video.vdo_thumbnail" :crop="false" :format="video.vdo_format"
                    id="player"
                    :controls="['play', 'play-large', 'progress', 'current-time', 'mute', 'volume', 'fullscreen']" />

                <div v-if="video != null && !embed" style="width: fit-content;">
                    <p style="font-weight: bold;font-size: 2.4vh;margin-top: 16px;">{{ video.vdo_name }}</p>
                    <div style="font-size: 1.8vh;margin-top: 4px;">
                        {{ $t('ShareView.by') }} {{ video.user.usr_firstname }} {{ video.user.usr_lastname }} -
                        {{ displayDate(video.delivered_at) }}
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>


<script>
import { displayDate } from "../../lib/date-time-duration.js"
import axios from 'axios'

import loadEmbedStyle from './loadEmbedStyle.vue'

export default {
    components: { loadEmbedStyle },
    data: function () {
        return {
            embed: true,
            token: null,
            tokenDecoded: null,
            video: null,
            user: null
        };
    },
    mounted() {
    
        if(this.$route.query.embed == undefined || Boolean(this.$route.query.embed) == false ){
            this.embed = false
        }

        let short_id = this.$route.params.short_id;

        axios.get('/videos/share/' + short_id)
            .then((res) => {
                this.video = res.data
                document.title = this.video.vdo_name
            })
    },
    methods: {
        displayDate: displayDate
    }


}

</script>

<style src="./share.css" scoped>
</style>